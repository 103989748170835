import axios from "axios";
import urlJoin from "url-join";

import configs from "../config.json";

import { getLanguage } from "localization/localizer.js";

export const Login = (email, password) => {
  return axios.get(
    urlJoin(configs.apiUrl, `/login/${email}/${password}`, `?_=${Date.now()}`),
    {},
    {}
  );
};

export const GetAccountInfo = (email, token) => {
  return axios.get(
    urlJoin(configs.apiUrl, `/account/${email}`, `?_=${Date.now()}`),
    {
      headers: {
        "X-Kaaiken-Api-Token": token,
      },
    }
  );
};

export const GetPaymentInfo = (orderId, token) => {
  return axios.get(
    urlJoin(configs.apiUrl, `/paymentInfo/${orderId}`, `?_=${Date.now()}`),
    {
      headers: {
        "X-Kaaiken-Api-Token": token,
      },
    }
  );
};

export const GetAllAccounts = (token) => {
  return axios.get(urlJoin(configs.apiUrl, `/accounts`), {
    headers: {
      "X-Kaaiken-Api-Token": token,
    },
  });
};

export const GetAllStandhouders = (token) => {
  return axios.get(urlJoin(configs.apiUrl, `/standhouders`), {
    headers: {
      "X-Kaaiken-Api-Token": token,
    },
  });
};

export const GetAllStandhoudersVorigJaar = (token) => {
  return axios.get(urlJoin(configs.apiUrl, `/standhoudersVorigJaar`), {
    headers: {
      "X-Kaaiken-Api-Token": token,
    },
  });
};

export const GetAllStandplaatsen = () => {
  return axios.get(urlJoin(configs.apiUrl, `/standplaatsen`), {});
};

export const GetStandplaatsenForID = (id, token) => {
  return axios.get(urlJoin(configs.apiUrl, `/standplaatsen/${id}`), {
    headers: {
      "X-Kaaiken-Api-Token": token,
    },
  });
};

export const InviteBewoners = (token) => {
  return axios.post(
    urlJoin(configs.apiUrl, `/inviteBewoners`),
    {},
    {
      headers: {
        "X-Kaaiken-Api-Token": token,
      },
    }
  );
};

export const MessageLastYear = (token, email) => {
  return axios.post(
    urlJoin(configs.apiUrl, `/messageLastYear/${email}`),
    {},
    {
      headers: {
        "X-Kaaiken-Api-Token": token,
      },
    }
  );
};

export const GetStandplaatsenLastYearForID = (id, token) => {
  return axios.get(urlJoin(configs.apiUrl, `/standplaatsenLastYear/${id}`), {
    headers: {
      "X-Kaaiken-Api-Token": token,
    },
  });
};

export const GetUniqueId = (token) => {
  return axios.get(urlJoin(configs.apiUrl, `/uniqueId`), {
    headers: {
      "X-Kaaiken-Api-Token": token,
    },
  });
};

export const StartPaymentInDB = (token, body) => {
  return axios.post(urlJoin(configs.apiUrl, `/v2/startPayment`), body, {
    headers: {
      "X-Kaaiken-Api-Token": token,
    },
  });
};

export const UpdateStandplaatsen = (standplaatsen, token) => {
  return axios.put(
    urlJoin(configs.apiUrl, `/standplaatsen`, `?_=${Date.now()}`),
    standplaatsen,
    {
      headers: {
        "X-Kaaiken-Api-Token": token,
      },
    }
  );
};

export const UpdateAccount = (id, data, token) => {
  return axios.put(
    urlJoin(configs.apiUrl, `account/${id}`, `?_=${Date.now()}`),
    data,
    {
      headers: {
        "X-Kaaiken-Api-Token": token,
      },
    }
  );
};

export const UpdateStandplaats = (standplaats, bezet, token) => {
  return axios.put(
    urlJoin(configs.apiUrl, `/standplaats/${standplaats}/${bezet}`),
    {},
    {
      headers: {
        "X-Kaaiken-Api-Token": token,
      },
    }
  );
};

export const RegisterAccount = (data) => {
  return axios.post(
    urlJoin(configs.apiUrl, `register`, `?_=${Date.now()}`),
    data,
    {}
  );
};

export const AddToMarkt = (data, token) => {
  return axios.post(
    urlJoin(configs.apiUrl, `addStandhouder`, `?_=${Date.now()}`),
    data,
    {
      headers: {
        "X-Kaaiken-Api-Token": token,
      },
    }
  );
};

export const RemoveFromMarkt = (data, token) => {
  return axios.post(
    urlJoin(configs.apiUrl, `removeStandhouder`, `?_=${Date.now()}`),
    data,
    {
      headers: {
        "X-Kaaiken-Api-Token": token,
      },
    }
  );
};

export const RequestResetPassword = (data) => {
  const lang = getLanguage();

  return axios.post(
    urlJoin(configs.apiUrl, `requestResetPassword`, lang, `?_=${Date.now()}`),
    data
  );
};

export const ResetPassword = (data) => {
  return axios.post(
    urlJoin(configs.apiUrl, `resetPassword`, `?_=${Date.now()}`),
    data
  );
};
