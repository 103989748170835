import React, { useEffect, useState } from "react";
import { GetPaymentInfo } from "api/calls";
import { useAuthState } from "stores/AuthStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

// Styling
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 10px;
  margin-bottom: 20px;
`;

const SuccessIcon = styled(FontAwesomeIcon)`
  color: green;
  font-size: 5rem;
`;

const FailedIcon = styled(FontAwesomeIcon)`
  color: red;
  font-size: 5rem;
`;

const Description = styled.div`
  margin-top: 20px;
`;

const PayedPage = () => {
  const { AuthState } = useAuthState();
  const { token } = AuthState;

  const [paymentInfo, setPaymentInfo] = useState();

  useEffect(() => {
    const index = window.location.href.indexOf("orderId");
    const orderId = window.location.href.slice(index + 8);

    GetPaymentInfo(orderId, token).then((result) => {
      if (result.data) {
        setPaymentInfo(result.data[0]);
      }
    });
  }, [token]);

  let title = "Betalingsgegevens opvragen...";
  if (paymentInfo) {
    if (paymentInfo.Status === "PAID") {
      title = `Betaling is geslaagd`;
    } else {
      title = `Betaling is mislukt`;
    }
  }

  let icon = "";
  if (paymentInfo) {
    if (paymentInfo.Status === "PAID") {
      icon = <SuccessIcon icon={faCheckCircle} />;
    } else {
      icon = <FailedIcon icon={faTimesCircle} />;
    }
  }

  let description = "";
  if (paymentInfo) {
    if (paymentInfo.Status === "PAID") {
      description = `Uw betaling voor ${paymentInfo.Standplaatsen.replaceAll(
        ",",
        " & "
      )} is gelukt. Je kan deze pagina sluiten`;
    } else {
      description = `Uw betaling voor ${paymentInfo.Standplaatsen} is mislukt. Probeer het opnieuw of neem contact op met de organisatie`;
    }
  }

  return (
    <Wrapper>
      <Title>{title}</Title>
      {icon}
      <Description>{description}</Description>
    </Wrapper>
  );
};

export default PayedPage;
